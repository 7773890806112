import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    body: "Raleway, sans-serif",
    heading: "Raleway, sans-serif",
    mono: "monospace",
  },
  styles: {
    global: {
      ".small-caps": {
        fontVariant: "small-caps",
      },
      a: {
        _hover: {
          textDecoration: "underline",
          color: "blue.600",
        },
      },
      blockquote: {
        my: 6,
        mx: [4, null, null, 24],
        fontStyle: "italic",
        fontSize: ["md", "lg", "xl"],
        textAlign: "center",
      },
      body: {
        bgGradient: "radial(gray.700, gray.900)",
        backgroundAttachment: "fixed",
        textColor: "gray.50",
        textAlign: "center",
      },
      "h1, h2, h3, h4, h5, h6": {
        fontVariant: "small-caps",
      },
      h4: {
        fontWeight: "semibold",
        fontStyle: "italic",
      },
      h6: {
        fontWeight: "semibold",
        fontStyle: "italic",
      },
      li: {
        py: 1,
      },
      ol: {
        py: 2,
        pl: 4,
      },
      p: {
        py: 2,
      },
      ul: {
        py: 2,
        pl: 4,
      },
    },
  },
  textStyles: {
    mdx: {
      heading: {
        pt: 8,
        pb: 4,
      },
    },
  },
});

export default theme;
