exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-portfolio-index-jsx": () => import("./../../../src/pages/portfolio/index.jsx" /* webpackChunkName: "component---src-pages-portfolio-index-jsx" */),
  "component---src-templates-portfolio-section-jsx": () => import("./../../../src/templates/portfolioSection.jsx" /* webpackChunkName: "component---src-templates-portfolio-section-jsx" */),
  "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-blogs-copy-mdx": () => import("./../../../src/templates/portfolioSection.jsx?__contentFilePath=/home/runner/work/portfolio_nikitas/portfolio_nikitas/src/gdrive/portfolio/blogs/copy.mdx" /* webpackChunkName: "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-blogs-copy-mdx" */),
  "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-ebooks-copy-mdx": () => import("./../../../src/templates/portfolioSection.jsx?__contentFilePath=/home/runner/work/portfolio_nikitas/portfolio_nikitas/src/gdrive/portfolio/ebooks/copy.mdx" /* webpackChunkName: "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-ebooks-copy-mdx" */),
  "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-infographics-copy-mdx": () => import("./../../../src/templates/portfolioSection.jsx?__contentFilePath=/home/runner/work/portfolio_nikitas/portfolio_nikitas/src/gdrive/portfolio/infographics/copy.mdx" /* webpackChunkName: "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-infographics-copy-mdx" */),
  "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-product-pages-copy-mdx": () => import("./../../../src/templates/portfolioSection.jsx?__contentFilePath=/home/runner/work/portfolio_nikitas/portfolio_nikitas/src/gdrive/portfolio/product-pages/copy.mdx" /* webpackChunkName: "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-product-pages-copy-mdx" */),
  "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-product-releases-copy-mdx": () => import("./../../../src/templates/portfolioSection.jsx?__contentFilePath=/home/runner/work/portfolio_nikitas/portfolio_nikitas/src/gdrive/portfolio/product-releases/copy.mdx" /* webpackChunkName: "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-product-releases-copy-mdx" */),
  "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-sell-sheets-copy-mdx": () => import("./../../../src/templates/portfolioSection.jsx?__contentFilePath=/home/runner/work/portfolio_nikitas/portfolio_nikitas/src/gdrive/portfolio/sell-sheets/copy.mdx" /* webpackChunkName: "component---src-templates-portfolio-section-jsx-content-file-path-home-runner-work-portfolio-nikitas-portfolio-nikitas-src-gdrive-portfolio-sell-sheets-copy-mdx" */)
}

